import React from 'react'
import Layout from '../components/Layout'
import img from '../images/happy-beautiful-woman-with-long-brunette-hair-after-cosmetology-therapy-smiling-mirror-white-room-joy-happines-good-results-true-positive-emotions.webp'
import img2 from '../images/laser-epilation-hair-removal-therapy.webp'
import img3 from '../images/gabinet-epilacji.webp'
import img4 from '../images/man-getting-facial-massage-clinic.webp'
import voucherImg from '../images/voucher.webp'
import {Link} from 'gatsby'
import {Fade} from 'react-reveal'
import {Seo} from '../components/Seo'

const EpilacjaLaserowa = () => {
    return (
        <>
            <Layout>
                <Seo title='Epilacja Laserowa | Modelowanie Sylwetki' />

                <div className='page-container'>
                    <div className='page multi epilacja'>
                        <div className='content-1'>
                            <h1>Epilacja Laserowa</h1>

                            <div className='section'>
                                <div className='image-container big'>
                                    <img
                                        src={img2}
                                        alt='Zdjęcie epilacji laserowej'
                                    ></img>
                                </div>
                                <div className='text'>
                                    <h2>
                                        EPILACJA LASEROWA JEST SKUTECZNIEJSZA NIŻ
                                        DEPILACJA
                                    </h2>
                                    <p>
                                        EPILACJA LASEROWA jest obecnie najskuteczniejszą
                                        metodą trwałego usuwania owłosienia. Zabieg
                                        wykonywany przy użyciu naszego lasera jest
                                        komfortowy, szybki i bezbolesny. Epilacja laserowa
                                        umożliwia trwałe usunięcie włosów ze wszystkich
                                        okolic ciała.
                                    </p>
                                    <h2>
                                        EPILACJA LASEROWA BIKINI, TWARZY, NÓG CZY PLECÓW–
                                        NASZ LASER SPRAWDZI SIĘ ZAWSZE I DLA KAŻDEGO
                                    </h2>
                                    <p>
                                        W naszym gabinecie wykonujemy epilację laserową
                                        dowolnych obszarów ciała, m.in. nóg, bikini, pach,
                                        twarzy czy pleców. Przewagą naszego lasera
                                        trójwiązkowego jest to, że po pierwsze jest
                                        bezbolesny i działa zarówno na ciemne, jak i jasne
                                        włosy oraz że zabiegi można wykonywać u osób o
                                        każdej karnacji. Nasz laser należy do
                                        najmocniejszych laserów do epilacji na rynku nie
                                        tylko w Polsce, ale w całej Europie. Jest laserem
                                        medycznym, diodowym zawierającym trzy wiązki
                                        działające w jednej głowicy. Zabiegi laserem z
                                        trzema wiązkami można wykonywać przez cały rok w
                                        obrębie całego ciała. Nowoczesny system chłodzenia
                                        głowicy sprawia, że zabieg jest bezbolesny.
                                    </p>
                                </div>
                            </div>
                            <hr className='ep' />
                            <div className='section'>
                                <div className='image-container'>
                                    <img
                                        src={img4}
                                        loading='lazy'
                                        alt='Zdjęcie epilacji laserowej'
                                    ></img>
                                </div>
                                <div className='text'>
                                    <h2>
                                        EPILACJA LASEROWA DLA KOBIET, DLA MĘŻCZYZN, DLA
                                        SPORTOWCÓW
                                    </h2>
                                    <p>
                                        Z zabiegu epilacji laserowej korzystają zarówno
                                        kobiety jak i mężczyźni. Osoby chętnie pozbywają
                                        się zbędnego owłosienia nie tylko ze względów
                                        estetycznych czy higienicznych, ale również ze
                                        względów zdrowotnych. Ponadto z epilacji rąk czy
                                        nóg coraz częściej korzystają sportowcy: kolarze,
                                        biegacze czy pływacy, którzy dzięki pozbyciu się
                                        owłosienia uzyskują lepsze sportowe wyniki.
                                    </p>
                                </div>
                            </div>

                            <div className='section reverse'>
                                <div className='text'>
                                    <h2>DZIAŁANIE LASERA DO EPILACJI</h2>
                                    <p>
                                        Laserowe usuwanie owłosienia jest zabiegiem
                                        powodującym długotrwałą redukcję włosów po
                                        wykonaniu serii zabiegowych. Technologii laserowej
                                        epilacji diodowej towarzyszy proces selektywnej
                                        fototermolizy, który zamienia energię świetlną w
                                        tkankach, na energię cieplną. Światło laserowe
                                        przenika przez powierzchnię skóry docierając do
                                        mieszków włosowych, gdzie dochodzi do uszkodzenia
                                        chromoforów, w tym przypadku melaniny. Światło
                                        zostaje zaabsorbowane i przekształcone w ciepło i
                                        w efekcie czego dochodzi do uszkodzenia komórek
                                        odpowiadających za wzrost włosa.
                                    </p>
                                </div>
                                <div className='image-container'>
                                    <img
                                        src={img3}
                                        loading='lazy'
                                        alt='Zdjęcie gabinetu epilacji laserowej'
                                    ></img>
                                </div>
                            </div>
                            <div className='section'>
                                <div className='image-container'>
                                    <img
                                        src={img}
                                        loading='lazy'
                                        alt='efekty epilacji laserowej'
                                    ></img>
                                </div>
                                <div className='text'>
                                    <h2>EFEKTY EPILACJI LASEROWEJ</h2>
                                    <p>
                                        Epilacja laserowa jest najskuteczniejszą metodą
                                        trwałego usuwania z każdego obszaru ciała, której
                                        efekty zostają na wiele lat. Po wykonaniu serii
                                        4-6 zabiegów w odstępach min. 4-6 tygodniowych, po
                                        18 miesiącach należy wykonać zabieg
                                        przypominający, który utrwali spektakularne
                                        rezultaty. Według naszych Klientów epilacja
                                        laserowa to ogromny komfort i wygoda, oszczędność
                                        czasu a przede wszystkim oszczędność pieniędzy. W
                                        ciągu kilkunastu tygodni Klient w trwały i
                                        bezpieczny sposób może pozbyć się na stałe
                                        zbędnego owłosienia.
                                    </p>
                                </div>
                            </div>
                            <div className='section'>
                                <div className='text'>
                                    <div className='cennik epilacja'>
                                        <span>CENNIK</span>
                                        <h2>NOGI</h2>
                                        <span className='alignRight'>
                                            1 ZABIEG / 6 ZABIEGÓW
                                        </span>
                                        <ul>
                                            <li>
                                                <p>ŁYDKI</p>
                                                <span className='prices'>
                                                    340 zł / 1305 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>ŁYDKI + KOLANA</p>
                                                <span className='prices'>
                                                    370 zł / 1550 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>UDA</p>
                                                <span className='prices'>
                                                    460 zł / 1850 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>UDA + KOLANA</p>
                                                <span className='prices'>
                                                    500 zł / 2100 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>KOLANA</p>
                                                <span className='prices'>
                                                    280 zł / 825 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>PALCE U STÓP</p>
                                                <span className='prices'>
                                                    140 zł / 420 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>STOPY</p>
                                                <span className='prices'>
                                                    140 zł / 570 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>CAŁE NOGI</p>
                                                <span className='prices'>
                                                    700 zł / 2900 zł
                                                </span>
                                            </li>
                                        </ul>
                                        <h2>BIKINI</h2>
                                        <span className='alignRight'>
                                            1 ZABIEG / 6 ZABIEGÓW
                                        </span>
                                        <ul>
                                            <li>
                                                <p>PODSTAWOWE (PACHWINY)</p>
                                                <span className='prices'>
                                                    240 zł / 800 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>ŚREDNIE (PACHWINY + WZGÓREK)</p>
                                                <span className='prices'>
                                                    260 zł / 950 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>PEŁNE (PACHWINY + WZGÓREK + WARGI)</p>
                                                <span className='prices'>
                                                    280 zł / 1100 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    GŁĘBOKIE (PACHWINY + WZGÓREK + WARGI+
                                                    SZPARA)
                                                </p>
                                                <span className='prices'>
                                                    420 zł /1450 zł / 1650 zł męskie
                                                </span>
                                            </li>
                                        </ul>
                                        <h2>INNE</h2>
                                        <span className='alignRight'>
                                            1 ZABIEG / 6 ZABIEGÓW
                                        </span>
                                        <ul>
                                            <li>
                                                <p>POŚLADKI</p>
                                                <span className='prices'>
                                                    300 zł / 900 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>SZPARA POŚLADKOWA</p>
                                                <span className='prices'>
                                                    150 zł / 600 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>KARK</p>
                                                <span className='prices'>
                                                    220 zł / 900 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>KLATKA PIERSIOWA</p>
                                                <span className='prices'>
                                                    280 zł / 1200 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>PLECY</p>
                                                <span className='prices'>
                                                    490 zł / 2350 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>BRZUCH</p>
                                                <span className='prices'>
                                                    330 zł / 1250 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>LINIA BRZUCHA</p>
                                                <span className='prices'>
                                                    150 zł / 590 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>OKOLICE BRODAWEK</p>
                                                <span className='prices'>
                                                    140 zł / 650 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>PACHY</p>
                                                <span className='prices'>
                                                    210 zł / 900 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>RAMIONA</p>
                                                <span className='prices'>
                                                    260 zł / 1200 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>PRZEDRAMIONA</p>
                                                <span className='prices'>
                                                    260 zł / 1200 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>CAŁE RĘCE</p>
                                                <span className='prices'>
                                                    420 zł / 1450 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>DŁONIE</p>
                                                <span className='prices'>
                                                    200 zł / 900 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>PALCE U DŁONI</p>
                                                <span className='prices'>
                                                    140 zł / 650 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>WĄSIK</p>
                                                <span className='prices'>
                                                    140 zł / 650 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>BRODA</p>
                                                <span className='prices'>
                                                    170 zł / 700 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>BAKI</p>
                                                <span className='prices'>
                                                    170 zł / 580zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>SZYJA/POLICZKI</p>
                                                <span className='prices'>
                                                    220 zł / 900 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>CAŁA TWARZ</p>
                                                <span className='prices'>
                                                    320 zł / 1500 zł
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='voucher-card'>
                                    <h3>Voucher</h3>
                                    <p>
                                        Spraw GENIALNY, ale przede wszystkim TRAFIONY i
                                        UNIKATOWY upominek swoim bliskim
                                    </p>
                                    <div className='image-container'>
                                        <img
                                            src={voucherImg}
                                            alt='voucher'
                                            loading='lazy'
                                        ></img>
                                    </div>
                                    <div>
                                        <Link to='/voucher'>
                                            <button>Więcej</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Fade left>
                            <Link to='/kontakt'>
                                <button>Umów Wizytę</button>
                            </Link>
                        </Fade>
                    </div>
                </div>
            </Layout>
            <div className='copyright-notice'>
                <a href='https://www.freepik.com/free-photo/laser-epilation-hair-removal-therapy_9820982.htm'>
                    Image by senivpetro
                </a>
                <a href='https://www.freepik.com/free-photo/man-getting-facial-massage-clinic_8403555.htm'>
                    Image by wavebreakmedia_micro
                </a>
                <a href='https://www.freepik.com/free-photo/happy-beautiful-woman-with-long-brunette-hair-after-cosmetology-therapy-smiling-mirror-white-room-joy-happines-good-results-true-positive-emotions_10214231.htm'>
                    Image by lookstudio
                </a>
                <div>on Freepik</div>
            </div>
        </>
    )
}

export default EpilacjaLaserowa
